<script>
import Layout from "../../../../layouts/main";
import CurrencyInput from "@/components/CurrencyInput";
import PDFViewer from 'pdf-viewer-vue'

export default {
  page: {
    meta: [
      {
        name: "description"
      },
    ],
  },
  components: {
    CurrencyInput,
    Layout,
    PDFViewer
  },
  data() {
    return {
      pdf_source:"",
      matchedAccounts: [],
      files: [],
      journal: {},
      journalEntries: [
        {
          descr: "",
          descr_en: "",
          type: "",
          value: null,
        },
        {
          descr: "",
          descr_en: "",
          type: "",
          value: null,
        },
      ],
    };
  },
  methods: {
    doScan(file){
      this.$loading.show()
      this.http
          .post("transactions/scan-document",{
            old_file: file.path
          })
          .then(resp=>{
            this.$loading.hide()
            file.path = resp.path
            console.log("Done",file)
          })
    },
    searchLevelThreeAccount(search) {
      this.http
        .post("level-three-chart-of-accounts/level-three/search", {
          search,
          existingAccounts:this.journalEntries.filter(journal=>journal.account && journal.account.id).map(journal=>journal.account.id)
        })
        .then((res) => {
          if (res.status) {
            this.matchedAccounts = res.data;
          }
        });
    },
    createJournal() {
      console.log("this.files",this.files);
      let filesObject = {};
      let files = []
      for (let i = 0; i < this.files.length; i++) {
          files.push(this.files[i].name)

        if(this.files[i].file)
            filesObject["file" + i] = this.files[i].file;
          else
            filesObject["file" + i] = this.files[i].path;
      }
      this.http
        .do("transactions/journal", {
          transactions: JSON.stringify(this.journalEntries),
          journal: JSON.stringify(this.journal),
          files: JSON.stringify(files),
          ...filesObject,
        })
        .then((res) => {
          if (res.status) {
            this.journalEntries = [
              {
                descr: "",
                descr_en: "",
                type: "",
                value: null,
              },
              {
                descr: "",
                descr_en: "",
                type: "",
                value: null,
              },
            ];
            this.journal = {};
            this.files = []
            this.popup.alert({
              title: "popups.done",
              msg: "popups.success",
            });
          }
        });
    },
    showLabel(account){
      return (this.$i18n.locale == "ar"
          ? account.code + "-" + account.name
          : account.code + "-" + account.name_en) +
          (account?.symbol
          ? " ("+account?.symbol+")"
          : account?.short_name ? " ("+account?.short_name +")": "")
    },
    deleteFile(index){
      if(this.files[index].path){
        this.http
            .post("transactions/delete-document", {
              path:this.files[index].path
            })
            .then((res) => {
              if (res.status) {
                this.files.splice(index, 1)
              }
            })
      }
      else{
        this.files.splice(index, 1)
      }
    },
    printDocument(file){
      if(location.href.includes("sayedmustafa.com")){
        let file_name = file.split("/")
        file_name = file_name[file_name.length-1]
        this.http.download("pos-chart-of-accounts/tree/download",file_name , {
          file
        })
      }
      else
      this.http
          .post("transactions/print-document", {
            file
          })
          .then((res) => {
            if(res.status){
              this.popup.alert({
                title: "popups.done",
                msg: "popups.success",
              });
            }
          })
    }

  },
  computed: {
    totalDebit() {
      let total = 0;
      this.journalEntries.forEach((tr) => {
        if (tr.type == "debit") {
          total += tr.value ? parseFloat(tr.value) : 0;
        }
      });
      return total;
    },
    totalCredit() {
      let total = 0;
      this.journalEntries.forEach((tr) => {
        if (tr.type == "credit") {
          total += tr.value ? parseFloat(tr.value) : 0;
        }
      });
      return total;
    },
    filesAreGood(){
      return this.files.length==0 || this.files.every(file=>file.file || file.path)
    },
    journalsAreGood(){
      return this.journalEntries.every(journal=>journal.account && journal.account.id && journal.value && journal.type)
    }
  },
  mounted() {

  },
};
</script>

<template>
  <Layout>

    <div class="row" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/journal_entries`">
            <h4 class="m-0">
              {{ $t("journal.title") }}
            </h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>{{ $t("journal.new_journal") }}</div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <div class="card card-body">
          <div class="row mb-2">
            <div></div>
            <div>
              <button
                @click="
                  journalEntries.push({
                    accountId: null,
                    descr: '',
                    descr_en: '',
                    type: 0,
                    value: null,
                  })
                "
                class="btn btn-primary text-light float-end"
              >
                <i class="bx bx-plus"></i>
              </button>
            </div>
          </div>
          <form>
            <div
              v-for="(journal, index) in journalEntries"
              :key="journal"
              class="journal-container"
            >
              <div class="row">
                <div class="col-12 col-sm-6 col-md-5 mt-3 mt-md-1">
                  <AutoComplete
                      v-model="journal.account"
                      :suggestions="matchedAccounts"

                      @complete="searchLevelThreeAccount($event.query)"
                      :complete-on-focus="true"
                      :optionLabel="showLabel"
                    required
                    style="color: #333 !important"
                  />
                </div>

                <div class="col-12 col-sm-6 col-md-3 mt-3 mt-md-1">
                  <div class="form-check form-check-inline">
                    <input
                      v-model="journal.type"
                      class="form-check-input"
                      type="radio"
                      value="credit"
                      :id="'creditVal'+index"
                    />
                    <label class="form-check-label" :for="'creditVal'+index"
                      >{{ $t("journal.credit") }}
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      v-model="journal.type"
                      class="form-check-input"
                      type="radio"
                      value="debit"
                      :id="'debitVal'+index"
                    />
                    <label class="form-check-label" :for="'debitVal'+index"
                      >{{ $t("journal.debit") }}
                    </label>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 mt-3 mt-md-1">
                  <div class="d-flex">
                    <currency-input v-model="journal.value" step="10" required class="form-control" :placeholder="`${$t('journal.value')}`" style="display: inline; margin-inline-end: 4px"></currency-input>
                    <button
                      :disabled="journalEntries.length <= 2"
                      class="btn btn-danger"
                      @click="journalEntries.splice(index, 1)"
                    >
                      <i class="bx bx-trash"></i>
                    </button>

                  </div>
                  <br>
                  <small v-if="journal?.account?.current_rate">
                    <b>{{processCurrency(journal.account,(journal.value/journal.account.current_rate),true)}}</b>
                  </small>
                </div>
              </div>
              <hr>
            </div>
            <div class="row">
              <div class="col hidden">

              </div>
              <div class="col hidden">
                <!-- <label class="inline" for="">description</label> -->
                <textarea
                  style="visibility: hidden"
                  class="form-control mb-2"
                  rows="1"
                  cols="4"
                  name=""
                  id=""
                ></textarea>
                <textarea
                  style="visibility: hidden"
                  class="form-control"
                  rows="1"
                  cols="4"
                  name=""
                  id=""
                ></textarea>
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input
                    style="visibility: hidden"
                    class="form-check-input"
                    type="radio"
                    value="credit"
                  />
                  <label

                    style="margin-inline-end: 2rem"
                    >{{
                      totalDebit > totalCredit ? (totalDebit - totalCredit).toLocaleString() : 0
                    }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    style="visibility: hidden"
                    value="debit"
                  />
                  <label
                    >{{
                      totalCredit > totalDebit ? (totalCredit - totalDebit).toLocaleString() : 0
                    }}
                  </label>
                </div>
              </div>
              <div class="col-2 hidden">
                <!-- <label class="inline" for="">Value</label> -->

                <div class="d-flex">
                  <input
                    type="number"
                    class="form-control"
                    style="
                      display: inline;
                      margin-inline-end: 4px;
                      visibility: hidden;
                    "
                  />
                  <button
                    style="visibility: hidden"
                    class="btn btn-danger hidden"
                  >
                    <i class="bx bx-trash"></i>
                  </button>
                </div>
              </div>

            </div>
            <div class="row d-flex align-items-center">
              <div class="mb-3">
                <label for="" class="form-label">{{
                    $t("journal.entry_descr")
                  }}</label>
              </div>
            </div>
            <div class="row d-flex align-items-center">
              <div class="mb-3">
                  <textarea
                      :placeholder="`${$t('journal.descr')}`"
                      name=""
                      v-model="journal.descr"
                      cols="10"
                      rows="5"
                      class="form-control mb-3"
                  ></textarea>
                <textarea
                    :placeholder="`${$t('journal.descr_en')}`"
                    name=""
                    v-model="journal.descr_en"
                    cols="10"
                    rows="5"
                    class="form-control"
                ></textarea>
              </div>
            </div>
            <hr />

            <!-- Journal Files -->
            <h5>{{$t('journal.documents')}}</h5>
            <button class="btn btn-primary" type="button" @click="files.push({})">
              <span class="fa fa-file-circle-plus"></span>
            </button>
            <br>
            <div class="row" v-for="(file,index) in files" :key="file">
              <div class="col-12 col-md-4 mt-3">
                <input type="text" class="form-control" v-model="file.name" :placeholder="$t('journal.doc_title')">
              </div>
              <div class="col-12 col-md-4 mt-3">
                <input :disabled="file.path" type="file" @change="file.file = $event.target.files[0]" class="form-control" />
              </div>
              <div class="col-12 col-md-3 mt-3 d-flex">
                <button :disabled="file.file" type="button" class="ms-1 btn btn-primary w-auto" @click="doScan(file)">
                  <span class="fa fa-file-image"></span>
                </button>
                <a v-if="file?.path" class="ms-1 btn btn-primary w-auto"
                   target="_blank"
                   :href="`${$store.state.filesUrl}${file.path?.split('public/')[1]}`"
                >{{ $t("emp_files.download") }}</a>
                <a v-if="file?.path" class="ms-1 btn btn-success" data-bs-toggle="modal" data-bs-target="#pdf-view" @click="pdf_source = $store.state.filesUrl+file.path?.split('public/')[1]">
                  {{ $t("popups.preview") }}
                </a><a v-if="file?.path" class="ms-1 btn btn-success" @click="printDocument(file?.path)">
                  {{ $t("popups.print") }}
                </a>
              </div>
              <div class="col-12 col-md-1 mt-3">
                <button
                    class="btn btn-danger"
                    @click="deleteFile(index)"
                >
                  <i class="bx bx-trash"></i>
                </button>
              </div>
            </div>
            <hr />



            <div class="row">
              <div></div>
              <div>
                <button
                  type="button"
                  role="button"
                  :disabled="
                    !totalCredit || !totalDebit || totalCredit != totalDebit || !filesAreGood || !journalsAreGood
                  "
                  @click="createJournal"
                  class="btn btn-primary float-end"
                >
                  {{ $t("journal.add") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal fade" id="pdf-view" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
         :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
      <div class="modal-dialog modal-lg" style="max-width: 1000px;height: 93vh;">
        <div class="modal-content" style="height: 100% !important;">
          <div class="modal-header">
            <h5 class="modal-title invisible" id="exampleModalLabel">
              PDV Viewer
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="margin: 0"></button>
          </div>

          <div class="modal-body">
            <PDFViewer
                :source="pdf_source"
                style="height: 100%; width: 100%"
            />
          </div>
        </div>
      </div>
    </div>


  </Layout>
</template>

<style soped>
.journal-container {
  background: #fafafa;
  /* margin: 1rem; */
  border-radius: 0.5rem;
  padding: 1rem;
}
</style>
